import React, { useState, useMemo, useEffect } from 'react'
import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons'

interface Option {
  value: string
  label: string
  group: string
}

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 4.5L4.5 13.5"
          stroke="#86C14C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L13.5 13.5"
          stroke="#86C14C"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.MultiValueRemove>
  )
}

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5 4.5L4.5 13.5"
          stroke="#8091A3"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 4.5L13.5 13.5"
          stroke="#8091A3"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.ClearIndicator>
  )
}

const Control = ({ children, ...props }: any) => {
  return (
    <components.Control {...props}>
      <div className="search-icon-wrapper">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
            stroke="#8091A3"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.7508 15.7469L12.4883 12.4844"
            stroke="#8091A3"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {children}
    </components.Control>
  )
}

// Remove dropdown indicator
const DropdownIndicator = () => null

// Remove separator between value and dropdown indicator
const IndicatorSeparator = () => null

// Customize the Group Heading
const GroupHeading = (props: any) => {
  return (
    <components.GroupHeading {...props}>
      <div className="group-heading">{props.children}</div>
    </components.GroupHeading>
  )
}

interface HubspotFieldSelectorProps {
  options: Option[]
  onChange?: (selectedOptions: Option[]) => void
  value?: Option[]
}

export const HubspotFieldSelector: React.FC<HubspotFieldSelectorProps> = ({
  options,
  onChange,
  value
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(value || [])

  useEffect(() => {
    if (value) {
      setSelectedOptions(value)
    }
  }, [value])

  const groupedOptions = useMemo(() => {
    return options.reduce(
      (acc: { label: string; options: Option[] }[], option) => {
        const group = acc.find((g) => g.label === option.group)
        if (group) {
          group.options.push(option)
        } else {
          acc.push({ label: option.group, options: [option] })
        }
        return acc
      },
      []
    )
  }, [options])

  const handleChange = (newValue: any) => {
    const selected = newValue as Option[]
    if (selected.length <= 10) {
      setSelectedOptions(selected)
      onChange?.(selected)
    }
  }

  return (
    <div className="mb-4">
      <div className="position-relative">
        <Select
          isMulti
          options={groupedOptions}
          value={selectedOptions}
          onChange={handleChange}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Search Category"
          components={{
            ClearIndicator,
            MultiValueRemove,
            Control,
            DropdownIndicator,
            IndicatorSeparator,
            GroupHeading
          }}
          styles={{
            container: (base) => ({
              ...base,
              maxWidth: '700px'
            }),
            control: (base) => ({
              ...base,
              minHeight: '48px',
              backgroundColor: '#F6FAFB',
              borderColor: '#dee2e6',
              borderRadius: '10px',
              boxShadow: 'none',
              paddingLeft: '0',
              '&:hover': {
                borderColor: '#ced4da'
              }
            }),
            menuList: (base) => ({
              ...base
            }),
            multiValue: (base) => ({
              ...base,
              backgroundColor: '#e6f7e6',
              borderRadius: '50px',
              padding: '2px 8px',
              border: '1px solid #86C14C'
            }),
            multiValueLabel: (base) => ({
              ...base,
              color: '#1B3A5A',
              fontSize: '14px',
              fontWeight: 400
            }),
            multiValueRemove: (base) => ({
              ...base,
              color: '#86C14C',
              svg: {
                width: '16px',
                height: '16px'
              },
              ':hover': {
                backgroundColor: 'transparent',
                color: '#86C14C'
              }
            }),
            input: (base) => ({
              ...base,
              margin: 0,
              padding: 0
            }),
            valueContainer: (base) => ({
              ...base,
              padding: '5px 8px'
            }),
            menu: (base) => ({
              ...base,
              borderRadius: '10px',
              zIndex: 9999,
              overflow: 'hidden'
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused ? '#f8f9fa' : 'white',
              color: '#022448',
              fontSize: '14px',
              ':active': {
                backgroundColor: '#e9ecef'
              }
            })
          }}
        />
        {selectedOptions.length >= 10 && (
          <div className="position-absolute text-danger small tag-limit-warning">
            Maximum of 10 tags allowed
          </div>
        )}
        <div className="text-danger small tag-limit-info">
          *use up to 10 tags
        </div>
      </div>
    </div>
  )
}

export default HubspotFieldSelector
