import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getHubspotContactFields,
  saveHubspotSelectedFields,
  syncHubspotContactFields,
  bulkImportContacts
} from '~/api/integrations'
import { useStore } from '~/dataStore'
import { NotificationType, showNotification } from '~/utils/Notification'

export enum ServerStateKeysEnum {
  HubspotFields = 'hubspotFields',
  HubspotSync = 'hubspotSync'
}

export const useHubspotFields = () => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  return useQuery({
    queryKey: [ServerStateKeysEnum.HubspotFields, appId],
    queryFn: () => getHubspotContactFields(appId)
  })
}

export const useHubspotSync = () => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  const cache = useQueryClient()

  return useMutation({
    mutationFn: () => syncHubspotContactFields(appId),
    onSuccess: () => {
      cache.invalidateQueries([ServerStateKeysEnum.HubspotFields, appId])
      showNotification('Sync successful', NotificationType.SUCCESS)
    },
    onError: () => {
      showNotification('Failed to sync fields', NotificationType.ERROR)
    }
  })
}

export const useHubspotSaveSelectedFields = () => {
  const {
    app: {
      getAppDetails,
      currentApp: { id: appId }
    }
  } = useStore()
  return useMutation(
    (data: { fields: [] }) => saveHubspotSelectedFields(appId, data.fields),
    {
      onSuccess: () => {
        showNotification(
          'Contact fields saved successfully',
          NotificationType.SUCCESS
        )
        getAppDetails(appId)
      },
      onError: () => {
        showNotification(
          'Failed to save contact fields',
          NotificationType.ERROR
        )
      }
    }
  )
}

export const useHubspotBulkImportContacts = () => {
  const {
    app: {
      currentApp: { id: appId }
    }
  } = useStore()
  return useMutation(() => bulkImportContacts(appId), {
    onSuccess: () => {
      showNotification('Contacts import in progress', NotificationType.SUCCESS)
    },
    onError: () => {
      showNotification('Failed to import contacts', NotificationType.ERROR)
    }
  })
}
