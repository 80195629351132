import React from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import plugIcon from '~/components/icons/plug.svg'
import chevronRightIcon from '~/components/chevron-right.svg'
import integrationsIcon from '~/components/icons/integrations.svg'
import { hubspotLogo } from './integrationLogos'
import { useStore } from '~/dataStore'

const IntegrationsPopover = () => {
  const { app } = useStore()
  const integrations = [
    {
      id: 1,
      logo: { url: hubspotLogo, width: 132 },
      title: 'HubSpot',
      description:
        'Effortless Text Messaging for Business Growth.',
      conntectUrl: `/integrations/hubspot/connect?app_id=${app.appDetails.id}`,
      isConnected: app?.appDetails.hubspot.connected
    }
  ]
  return (
    <div>
      <div>
        <Button
          id="UncontrolledPopover"
          color="body"
          className="ps-15 pe-15 text-base fw-medium h-10 border-0 d-flex align-items-center">
          <img src={plugIcon} alt="Integrations" className="me-2" />
          Integrations
          <img src={chevronRightIcon} alt="chevron right" className="ms-2" />
        </Button>
        <UncontrolledPopover
          className="integrations-popover"
          placement="bottom-end"
          target="UncontrolledPopover">
          <PopoverBody>
            <h4>
              <img
                width="24"
                src={integrationsIcon}
                className="me-3"
                alt="Integrations"
              />
              Integrations
            </h4>
            {/* <HubSpotIntegration /> */}
            {integrations.map((integration) => (
              <div
                key={integration.id}
                className="bg-secondary-light-gray py-4 px-3 rounded-md mt-4">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-15 fw-medium text-brand-gray-800">
                    {integration.title}
                  </span>
                  {integration.isConnected ? (
                    <FontAwesomeIcon
                      size="xl"
                      className="text-brand-primary"
                      icon={faCircleCheck}
                    />
                  ) : (
                    <a
                      href={integration.conntectUrl}
                      className="btn btn-body text-brand-primary text-white roundex-xs text-14 fw-medium py-1 px-3 border-0">
                      Connect
                    </a>
                  )}
                </div>
                <img
                  src={integration.logo.url}
                  alt={integration.title}
                  width={integration.logo.width}
                  className="mt-4 pt-3 d-block mx-auto"
                />
                <p className="text-14 fw-light mt-4 text-gray-700 mb-0">
                  {integration.description}
                </p>
              </div>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    </div>
  )
}

export default IntegrationsPopover
