import React, { useMemo, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faQuestionCircle,
  faCircleCheck,
  faSpinner,
  faSync,
  faArrowsRotate
} from '@fortawesome/free-solid-svg-icons'
import { Button, Card, CardBody, UncontrolledTooltip } from 'reactstrap'
import HubspotFieldSelector from './components/HubspotFieldSelector'
import {
  useHubspotFields,
  useHubspotSync,
  useHubspotSaveSelectedFields,
  useHubspotBulkImportContacts
} from './useHubspot'
import { useStore, withStore } from '~/dataStore'
import importIcon from './components/importIcon.svg'

import './Hubspot.scss'

interface Option {
  value: string
  label: string
  group: string
}

export const Hubspot = () => {
  const { data, isLoading, error } = useHubspotFields()
  const { mutate: syncHubspotContactFields, isLoading: isSyncing } =
    useHubspotSync()
  const { mutate: saveHubspotSelectedFields, isLoading: isSaving } =
    useHubspotSaveSelectedFields()
  const { mutate: bulkImportContacts, isLoading: isImporting } =
    useHubspotBulkImportContacts()

  const {
    app: {
      appDetails: { hubspot }
    }
  } = useStore()
  const [selectedFields, setSelectedFields] = useState<Option[]>([])

  const hubspotOptions = useMemo(() => {
    if (!data) return []

    const options: Option[] = []

    Object.entries(data).forEach(([category, fields]) => {
      const formattedCategory = category.replace(/([A-Z])/g, ' $1').trim()

      Object.entries(fields as Record<string, string>).forEach(
        ([label, value]) => {
          options.push({
            value,
            label,
            group: formattedCategory
          })
        }
      )
    })

    return options
  }, [data])

  // Initialize selected fields from hubspot data
  useEffect(() => {
    if (!hubspot?.selectedFields || !hubspotOptions.length) return

    const initialSelectedFields = hubspotOptions.filter((option) =>
      hubspot.selectedFields.includes(option.value)
    )
    setSelectedFields(initialSelectedFields)
  }, [hubspot?.selectedFields, hubspotOptions])

  const handleFieldsChange = (newSelectedFields: Option[]) => {
    setSelectedFields(newSelectedFields)
  }

  const handleSave = () => {
    saveHubspotSelectedFields({
      fields: selectedFields.map((field) => field.value)
    })
  }

  return (
    <div className="hubspot-integration-page mt-1">
      <Card>
        <CardBody className="ps-4 ms-2 h-100">
          <p className="text-30 text-brand-gray-900">Hubspot Integration</p>
          <p className="mt-5 text-brand-gray-900">
            Hubspot Integration allows you to sync your hubspot contacts within
            pulsate.
          </p>
          <p className="fw-medium text-18 text-brand-gray-800 mt-4">
            There are 3 main steps:
          </p>
          <div className="mt-3">
            <ol className="main-steps-list">
              <li className="text-brand-gray-900">
                You need to create a new dynamic property named Alias for your
                HubSpot Contacts.
              </li>
              <li className="text-brand-gray-900">
                You need to upload respective pulsate's Alias into your HubSpot
                account for each contact you want to sync, you can export
                pulsate users list, grab all aliases, use hubspot's bulk upsert
                contacts functionality by adding aliases along with your
                contact's email. It will allow you to bulk upload Pulsate's
                alias into hubspot account.
              </li>
              <li className="text-brand-gray-900">
                <div>
                  Then you need to click on "Sync Fields", which will allow
                  pulsate to show you all respective HubSpot Contact Fields in
                  the dropdown
                </div>
                <ul className="nested-list">
                  <li>
                    Then you can select fields you want to sync. You can select
                    upto 10 fields at max.
                  </li>
                  <li>
                    Then you need to click on import button to sync HubSpot
                    Contacts within Pulsate
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <hr className="my-4 bg-brand-gray-100" />
          <div className="pt-4">
            <div className="d-flex align-items-center">
              <h2 className="h4 mb-0 fw-medium text-brand-gray-900">
                HubSpot Sync Fields
              </h2>
              <FontAwesomeIcon
                size="xl"
                icon={faQuestionCircle}
                className="ms-3 text-brand-secondary-700"
                id="sync-info"
              />

              <UncontrolledTooltip
                placement="bottom"
                target="sync-info"
                popperClassName="tooltip-new">
                <span>
                  Select HubSpot contact fields to sync seamlessly. Ensure
                  accurate mapping and data consistency for bulk imports and
                  smooth integration workflows.
                </span>
              </UncontrolledTooltip>

              <Button
                onClick={() => syncHubspotContactFields()}
                color="body"
                className="ms-4 rounded-sm px-15p text-base fw-medium h-10 border-0 d-flex align-items-center bg-brand-secondary"
                disabled={isSyncing}>
                <FontAwesomeIcon
                  spin={isSyncing}
                  icon={faSync}
                  className="me-2"
                />
                Sync Now
              </Button>
            </div>
            <div className="mt-4 pt-2">
              <p className="text-base fw-medium text-brand-gray-900">
                Select Hubspot Contact Fields
              </p>
              {isLoading ? (
                <div>Loading fields...</div>
              ) : error ? (
                <div>Error loading fields. Please try again.</div>
              ) : (
                <HubspotFieldSelector
                  options={hubspotOptions}
                  onChange={handleFieldsChange}
                  value={selectedFields}
                />
              )}
            </div>
          </div>
          <Button
            onClick={handleSave}
            color=""
            className="bg-brand-primary px-5 text-14 text-brand-gray-900 fw-medium h-10 border-0 d-flex align-items-center rounded-sm"
            disabled={isSaving}>
            {isSaving && (
              <FontAwesomeIcon
                className="me-1"
                icon={faSpinner}
                spin
                size="xs"
              />
            )}
            {isSaving ? 'Saving...' : 'Save'}
          </Button>

          <hr />
          <p className="mt-5 text-base fw-medium text-brand-gray-900 mb-1">
            Bulk Import Contacts From HubSpot
          </p>
          <p className="text-12 text-brand-warning-800">
            Please make sure to add Pulsate user's aliases into HubSpot account
            first before importing bulk contacts here
          </p>

          <div className="mt-4 pt-2">
            <div className="d-flex align-items-center">
              <div className="">
                <div className="bulk-import-container border  border-brand-gray-100 d-flex align-items-center justify-content-between bg-secondary-light-gray  rounded-sm">
                  <p className="fw-medium text-14 text-brand-gray-400 mb-0">
                    Bulk Import Contacts
                  </p>
                  <Button
                    color="body"
                    className="bg-brand-secondary px-2 text-14 text-brand-gray-900 fw-medium py-1  border-0 d-flex align-items-center rounded-sm text-white"
                    disabled={
                      hubspot?.selectedFields?.length === 0 ||
                      hubspot?.contactsSyncStatus === 'in_progress' ||
                      isImporting
                    }
                    onClick={() => bulkImportContacts()}>
                    <img
                      width={24}
                      height={24}
                      src={importIcon}
                      alt=""
                      className="me-2"
                    />
                    Import
                  </Button>
                </div>
              </div>
              {hubspot?.contactsSyncStatus === 'completed' && (
                <div className="ms-3">
                  <p className="fw-medium text-14 text-brand-gray-400 mb-0">
                    <FontAwesomeIcon
                      size="xl"
                      className="text-brand-primary me-2"
                      icon={faCircleCheck}
                    />
                    Contacts synced
                  </p>
                </div>
              )}
              {hubspot?.contactsSyncStatus === 'in_progress' && (
                <div className="ms-3">
                  <p className="fw-medium text-14 text-brand-gray-400 mb-0">
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faArrowsRotate}
                      spin
                      size="xl"
                    />
                    Contacts sync in progress
                  </p>
                </div>
              )}
            </div>
            <p className="text-12 text-brand-gray-700 mt-3">
              Note: Ensure no bulk import job is active before starting a new
              one.
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default withStore(Hubspot)
