import { get, post, put } from './v2'

export function getHubspotContactFields(appId: string): Promise<any> {
  return get(`${appId}/integrations/hubspot/get_contact_fields`)
}

export function syncHubspotContactFields(appId: string): Promise<any> {
  return post(`${appId}/integrations/hubspot/sync_contact_fields`)
}

export function saveHubspotSelectedFields(
  appId: string,
  selectedFields: string[]
): Promise<any> {
  return put(`${appId}/integrations/hubspot/save_selected_fields`, {
    selectedFields
  })
}

export function bulkImportContacts(appId: string): Promise<any> {
  return put(`${appId}/integrations/hubspot/bulk_import_contacts`)
}
